import React from 'react';

import Layout from '../components/layout/public';
import Hero from '../components/public/page-hero';
import Callout, { ForSchoolsCallout } from '../components/public/callout';
import Pricing from '../components/public/pricing';
import Faq from '../components/public/faq';
import SEO from '../components/seo';

const PornBlockerPage = () => (
	<Layout>
		<SEO
			title="Block websites and filter internet at school"
			description={`Block unwanted content or monitor internet activity at your school by switching to our DNS servers. Keep your school safe while browsing the internet.`}
		/>

		<Hero title={'Internet filtering for schools'} style={{
			marginBottom: 50,
		}}/>

		<ForSchoolsCallout style={{
			marginBottom: 50,
		}}/>

		<Pricing style={{
			marginBottom: 50,
		}}/>

		<Callout style={{
			marginBottom: 50,
		}}/>

		<Faq style={{
			marginBottom: 50,
		}}/>
	</Layout>
);


export default PornBlockerPage;
